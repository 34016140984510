import { Action, Dispatch, Middleware } from '@reduxjs/toolkit';
import { removeUserData } from './slices/userDataSlice';
interface RootState {
  auth: {
    isLogged: boolean;
  };
}

export const authMiddleware: Middleware =
  (storeApi) => (next: Dispatch) => (action: Action<string>) => {
    // Here, replace with your actual action type
    const actionsRequiringAuth = [
      'FETCH_USER_DATA',
      'UPDATE_USER_PROFILE',
      'DELETE_USER_ACCOUNT',
    ];

    if (actionsRequiringAuth.includes(action.type)) {
      const state: RootState = storeApi.getState();
      if (!state.auth.isLogged) {
        return storeApi.dispatch(removeUserData());
      }
    }

    return next(action);
  };
