import { createSlice, PayloadAction } from '@reduxjs/toolkit';
type TNestedData = {
  NDC: {
    domestic: {
      flat: number;
      percent: number;
    };
    international: {
      flat: number;
      percent: number;
    };
  };
  ALL: {
    domestic: {
      flat: number;
      percent: number;
    };
    international: {
      flat: number;
      percent: number;
    };
  };
  LCC: {
    domestic: {
      flat: number;
      percent: number;
    };
    international: {
      flat: number;
      percent: number;
    };
  };
};

type TApi = {
  B2C: TNestedData;
  B2B: TNestedData;
  VIP: TNestedData;
  api: string;
  level: number;
  useGross: boolean;
  ait: number;
  status: boolean;
  isRefundable: boolean;
  isHoldable: boolean;
  setInprocess: boolean;
};
type TAirline = {
  name: string;
  code: string;
  logo: string;
  status: boolean;
  api: TApi[];
  createdAt: string;
  updatedAt: string;
};

type TAirlinesDataState = {
  airlines: TAirline;
  isLoading: boolean;
  isSuccess: boolean;
  error: boolean;
  errorMessage: string | null;
};

const initialState: TAirlinesDataState = {
  airlines: null,
  isLoading: false,
  isSuccess: false,
  error: false,
  errorMessage: null,
};
const airlinesDataSlice = createSlice({
  name: 'airlinesData',
  initialState,
  reducers: {
    airlinesDataFetchStart: (state) => {
      state.isLoading = true;
    },
    addAirlinesData: (state, action: PayloadAction<TAirline>) => {
      state.airlines = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.error = false;
      state.errorMessage = null;
    },
  },
});

export const { addAirlinesData, airlinesDataFetchStart } =
  airlinesDataSlice.actions;
export default airlinesDataSlice.reducer;
