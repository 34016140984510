import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TReissueDataState = {
  _id: string;
  PNR: string;
  reason: {
    type: string;
    message: string;
  };
  reqRoutes: {
    segmentNo: string;
    departureDate: string;
  }[];
  reqPassenger: {
    adultData: { tktNo: string }[];
    childData: { tktNo: string }[];
    infantData: { tktNo: string }[];
  };
};

const initialState: TReissueDataState = {
  _id: '',
  PNR: '',
  reason: {
    type: '',
    message: '',
  },
  reqRoutes: [],
  reqPassenger: {
    adultData: [],
    childData: [],
    infantData: [],
  },
};

const reissueSlice = createSlice({
  name: 'reissueData',
  initialState, // Initialize state as a object
  reducers: {
    addReissueData: (state, action: PayloadAction<any>) => {
      const { _id, PNR, reason, reqRoutes, reqPassenger } = action.payload;

      state._id = _id;
      state.PNR = PNR;
      state.reason = reason;
      state.reqRoutes = reqRoutes;
      state.reqPassenger = reqPassenger;
    },
    removeReissueData: (state) => {
      state._id = '';
      state.PNR = '';
      state.reason = {
        type: '',
        message: '',
      };
      state.reqRoutes = [];
      state.reqPassenger = {
        adultData: [],
        childData: [],
        infantData: [],
      };
    },
  },
});

export const { addReissueData, removeReissueData } = reissueSlice.actions;
export default reissueSlice.reducer;
