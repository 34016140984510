import { getBaseUrl } from '@/helpers/config/envConfig';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const flightSearchApi = createApi({
  reducerPath: 'flightSearchApi',
  baseQuery: fetchBaseQuery({ baseUrl: getBaseUrl() }),
  tagTypes: ['Flight'], // Define your tag types here
  endpoints: (builder) => ({
    flightSearch: builder.mutation({
      query: ({ sid }) => ({
        url: `/sr/${sid}`,
        method: 'POST',
        body: { lorem: 'lorem' },
      }),
      invalidatesTags: [{ type: 'Flight', id: 'SEARCH' }], // Adjust to `invalidatesTags` if you intend to invalidate the cache
    }),
  }),
});

export const { useFlightSearchMutation } = flightSearchApi;
