import { configureStore } from '@reduxjs/toolkit';
import { flightSearchApi } from './api/flightSearchApi';
import { authMiddleware } from './authMiddleware';
import airControlReducer from './slices/airControlSlice';
import airportsDataReducer from './slices/airportsDataSlice';
import airlinesDataReducer from './slices/airlinesDataSlice';
import airportsNameReducer from './slices/airportsNameSlice';
import allPassengersDataReducer from './slices/allPassengersDataSlice';
import clientPriceReducer from './slices/clientPriceSlice';
import confirmBookReducer from './slices/confirmBookSlice';
import fareRulesReducer from './slices/fareRulesSlice';
import flightSearchReducer from './slices/flightSearchSlice';
import holdConfirmReducer from './slices/holdConfirmSlice';
import multiCityReducer from './slices/multicitySlice';
import setOneWayReducer from './slices/oneWaySlice';
import PassengerDetailsReducer from './slices/passengerDetails';
import reissueReducer from './slices/reissueSlice';
import returnReducer from './slices/returnSlice';
import searchResultsReducer from './slices/searchResultsSlice';
import searchTypeReducer from './slices/searchTypeSlice';
import timeGroupReducer from './slices/timeGroupSlice';
import typeGroupReducer from './slices/typeGroupSlice';
import userBalanceReducer from './slices/userBalanceSlice';
import userDataReducer from './slices/userDataSlice';
import userDepositsReducer from './slices/userDepositsSlice';
import userLedgerReducer from './slices/userLedgerSlice';
import userListsReducer from './slices/userListsSlice';
import userLoginReducer from './slices/userLoginSlice';
import userRoleReducer from './slices/userRoleSlice';
import userSignupReducer from './slices/userSignupSlice';
import notificationsDataReducer from './slices/notificationsDataSlice';

export const store = configureStore({
  reducer: {
    userLogin: userLoginReducer,
    userSignup: userSignupReducer,
    userData: userDataReducer,
    userRole: userRoleReducer,
    userLedger: userLedgerReducer,
    userDeposits: userDepositsReducer,
    userLists: userListsReducer,
    userBalance: userBalanceReducer,
    searchResults: searchResultsReducer,
    searchType: searchTypeReducer,
    oneWayState: setOneWayReducer,
    returnState: returnReducer,
    multiCityState: multiCityReducer,
    timeGroup: timeGroupReducer,
    typeGroup: typeGroupReducer,
    airControl: airControlReducer,
    airportsName: airportsNameReducer,
    airportsData: airportsDataReducer,
    airlinesData: airlinesDataReducer,
    fareRules: fareRulesReducer,
    passengerDetails: PassengerDetailsReducer,
    clientPrice: clientPriceReducer,
    confirmBook: confirmBookReducer,
    allPassengers: allPassengersDataReducer,
    holdConfirmed: holdConfirmReducer,
    reissue: reissueReducer,
    flightSearch: flightSearchReducer,
    notificationsData: notificationsDataReducer,
    [flightSearchApi.reducerPath]: flightSearchApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authMiddleware, flightSearchApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
