import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TAirport = {
  country: {
    name: string;
    code: string;
  };
  name: string;
  code: string;
  city: string;
};
type TAirportsDataState = {
  airports: TAirport;
  isLoading: boolean;
  isSuccess: boolean;
  error: boolean;
  errorMessage: string | null;
};

const initialState: TAirportsDataState = {
  airports: null,
  isLoading: false,
  isSuccess: false,
  error: false,
  errorMessage: null,
};
const airportsDataSlice = createSlice({
  name: 'airportsData',
  initialState,
  reducers: {
    airportsDataFetchStart: (state) => {
      state.isLoading = true;
    },
    addAirportsData: (state, action: PayloadAction<TAirport>) => {
      state.airports = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.error = false;
      state.errorMessage = null;
    },
  },
});

export const { addAirportsData, airportsDataFetchStart } =
  airportsDataSlice.actions;
export default airportsDataSlice.reducer;
