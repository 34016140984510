import { useEffect, useState } from 'react';
import { IoIosArrowDown } from '@/Icons/io';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { GrFormRefresh } from '@/Icons/gr/index.mjs';
import useSecureFetcher from '@/hooks/useSecureFetcher';
import { updateUserBalance } from '@/store/slices/userDataSlice';
import { IoWallet, IoWalletOutline } from '@Icons/io5';
import { Popover, PopoverContent, PopoverTrigger } from '../../ui/popover';
import { cn } from '@/utils/utils';

const MyBalance = () => {
  const user = useAppSelector((state) => state.userData.user);

  const [open, setOpen] = useState(false);
  const [alignValue, setAlignValue] = useState<'start' | 'center' | 'end'>(
    'end',
  );

  const dispatch = useAppDispatch();

  // Update the `align` value based on window size
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        // Large screens (≥1024px)
        setAlignValue('start');
      } else {
        // Smaller screens (<1024px)
        setAlignValue('end');
      }
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleBalanceRefresh = async () => {
    const response = await useSecureFetcher('v1/GetUserBalance', {});
    if (response.status !== 'error') {
      dispatch(updateUserBalance(response.balance));
    }
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger>
        <div
          className={`hidden items-center gap-1 text-sm font-medium duration-250 ease-in-out md:relative md:flex ${
            open ? 'text-primary_color' : 'hover:text-primary_color'
          }`}
        >
          <span>Balance</span>{' '}
          <span className="font-bold">
            ৳ {user?.balance.toString().split('.')[0]}
          </span>
          <span
            className={`mt-1 transition-all duration-250 ease-soft-spring ${
              open ? 'rotate-180' : 'rotate-0'
            }`}
          >
            <IoIosArrowDown />
          </span>
        </div>
        <div
          className={`flex size-8 select-none items-center justify-center rounded-lg text-xl outline-none duration-250 ease-in-out md:hidden ${
            open ? 'bg-primary_color/10' : 'bg-gray-100 hover:bg-gray-200'
          }`}
        >
          {open ? (
            <IoWallet className="text-primary_color" />
          ) : (
            <IoWalletOutline />
          )}
        </div>
      </PopoverTrigger>

      <PopoverContent
        align={alignValue}
        className={cn(
          'h-fit w-56 space-y-2 p-3 text-gray-500',
          alignValue === 'start' && 'mr-2',
        )}
      >
        <div className="flex items-center justify-between gap-1 text-sm font-medium">
          <p className="text">Balance </p>
          <p className="font-bold text-gray-700">BDT {user?.balance}</p>
        </div>
        <div className="flex items-center justify-between gap-1 text-sm font-medium">
          <p>Status</p>
          <p
            className={`font-bold ${
              user?.userStatus === 'Active'
                ? 'text-green-600'
                : 'text-tertiary_color'
            }`}
          >
            {' '}
            {user?.userStatus ? user?.userStatus : 'Inactive'}
          </p>
        </div>

        <div className="my-3 w-full border-b border-gray-200"></div>

        <div className="flex items-center justify-center">
          <button
            onClick={handleBalanceRefresh}
            className="flex items-center justify-center gap-[2px] text-xs font-medium duration-250 ease-in-out hover:text-primary_color active:scale-95"
          >
            <GrFormRefresh className="text-lg" /> Refresh
          </button>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default MyBalance;
