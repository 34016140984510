import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import React, { forwardRef, SVGAttributes, useState } from 'react';
import { Drawer, DrawerContent, DrawerTrigger } from '@/components/ui/drawer';
import { useAppDispatch } from '@/store/hooks';
import { removeUserData } from '@/store/slices/userDataSlice';
import { FaPowerOff, FaRegUserCircle } from '@/Icons/fa';
import { FcMenu } from '@/Icons/fc';
import { DrawerItem } from './DrawerItem';
import { flightSearchClose } from '@/store/slices/flightSearchSlice';
import { B2BMenu, B2CMenu, controls, manage } from '@/constants/menuItems';

// Define our own IconProps type
interface IconProps extends SVGAttributes<SVGElement> {
  size?: string | number;
  color?: string;
  title?: string;
}

const MenuIconWrapper = forwardRef<HTMLDivElement, IconProps>((props, ref) => (
  <div ref={ref}>
    <FcMenu {...props} />
  </div>
));
MenuIconWrapper.displayName = 'MenuIconWrapper';

const ResponsiveDrawer = ({ userInfo, isAuthenticated }) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const pathname = usePathname();

  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = () => {
    try {
      dispatch(removeUserData());
      // signOut({ redirectTo: "/login",redirect: false })
      localStorage.removeItem('userInfo');
      document.cookie =
        'userEmail=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie =
        'userCID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie =
        'userRole=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie =
        'userPhone=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      router.replace('/');
    } catch (error) {}
  };

  return (
    <Drawer open={isOpen} onOpenChange={setIsOpen} direction="left">
      <DrawerTrigger asChild className="cursor-pointer 2xl:hidden">
        <MenuIconWrapper className="text-2xl 2xl:hidden" />
      </DrawerTrigger>
      <DrawerContent className="h-dvh w-4/5 rounded-none border-0 p-0 outline-none dark:border dark:border-gray-800 dark:bg-gray-900 md:w-2/3 lg:w-1/2 2xl:hidden">
        <div className="responsive-scrollbar w-full overflow-y-auto pb-20">
          <div className="mx-4 mb-5 mt-4 flex items-center justify-between">
            <div
              onClick={() => {
                dispatch(flightSearchClose());
                setIsOpen(false);
                router.replace('/');
              }}
              className="flex cursor-pointer items-center 2xl:hidden"
            >
              <Image
                src="/tmlogo.png"
                alt="TicketMet Logo"
                width={36}
                height={36}
                className="size-7 rounded-full object-cover md:size-8"
              />

              <h1 className="text-xl font-bold tracking-tighter md:text-2xl">
                Ticketmet
              </h1>
            </div>

            {isAuthenticated && (
              <button
                onClick={() => {
                  handleLogout();
                }}
                style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}
                className="flex size-9 items-center justify-center rounded-full text-lg duration-200 ease-in-out active:scale-95 md:size-10 md:text-xl"
              >
                <FaPowerOff />
              </button>
            )}
          </div>

          {isAuthenticated ? (
            <>
              <div className="flex w-full flex-col items-center font-medium">
                {userInfo?.role === 'SUDO' || userInfo?.role === 'ADMIN' ? (
                  <>
                    <Link
                      href="/reports"
                      onClick={() => setIsOpen(false)}
                      className={`w-full px-5 py-2 text-sm duration-250 ease-in-out active:scale-95 ${
                        pathname === '/reports'
                          ? 'bg-primary_color/10 text-primary_color'
                          : 'hover:bg-primary_color/10 hover:text-primary_color'
                      }`}
                    >
                      Reports
                    </Link>
                    <DrawerItem
                      title="Controls"
                      menu={controls}
                      setIsOpen={setIsOpen}
                    />
                    <DrawerItem
                      title="Manage"
                      menu={manage}
                      setIsOpen={setIsOpen}
                    />
                    <Link
                      onClick={() => setIsOpen(false)}
                      href="/my-bookings"
                      className={`w-full px-5 py-2 text-sm duration-250 ease-in-out active:scale-95 ${
                        pathname === '/my-bookings'
                          ? 'bg-primary_color/10 text-primary_color'
                          : 'hover:bg-primary_color/10 hover:text-primary_color'
                      }`}
                    >
                      My Bookings
                    </Link>
                  </>
                ) : (
                  <></>
                )}

                {userInfo?.role === 'B2C' || userInfo?.role === 'VIP' ? (
                  <>
                    <Link
                      href="/my-bookings"
                      onClick={() => setIsOpen(false)}
                      className={`w-full px-5 py-2 text-sm duration-250 ease-in-out active:scale-95 ${
                        pathname === '/my-bookings'
                          ? 'bg-primary_color/10 text-primary_color'
                          : 'hover:bg-primary_color/10 hover:text-primary_color'
                      }`}
                    >
                      My Bookings
                    </Link>
                    <DrawerItem
                      title="Accounts"
                      menu={B2CMenu}
                      setIsOpen={setIsOpen}
                    />
                  </>
                ) : (
                  <></>
                )}

                {userInfo?.role === 'B2B' ? (
                  <>
                    <Link
                      href="/my-bookings"
                      onClick={() => setIsOpen(false)}
                      className={`w-full px-5 py-2 text-sm duration-250 ease-in-out active:scale-95 ${
                        pathname === '/my-bookings'
                          ? 'bg-primary_color/10 text-primary_color'
                          : 'hover:bg-primary_color/10 hover:text-primary_color'
                      }`}
                    >
                      My Bookings
                    </Link>
                    <DrawerItem
                      title="Accounts"
                      menu={B2BMenu}
                      setIsOpen={setIsOpen}
                    />
                  </>
                ) : (
                  <></>
                )}

                {userInfo?.role === 'MOD' ? (
                  <Link
                    href="/workboard"
                    onClick={() => setIsOpen(false)}
                    className={`w-full px-5 py-2 text-sm duration-250 ease-in-out active:scale-95 ${
                      pathname === '/workboard'
                        ? 'bg-primary_color/10 text-primary_color'
                        : 'hover:bg-primary_color/10 hover:text-primary_color'
                    }`}
                  >
                    Workboard
                  </Link>
                ) : (
                  <></>
                )}
                {/* <DrawerItem
                  title="Others"
                  menu={others}
                /> */}
              </div>

              <hr className="m-5 dark:border-gray-700" />
              <Link
                href="/my-profile"
                onClick={() => setIsOpen(false)}
                className={`flex w-full items-center px-5 py-2 text-sm font-medium duration-250 ease-in-out active:scale-95 ${
                  pathname === '/my-profile'
                    ? 'bg-primary_color/10 text-primary_color'
                    : 'hover:bg-primary_color/10 hover:text-primary_color'
                }`}
              >
                My Profile
              </Link>
            </>
          ) : (
            <>
              <div className="flex w-full flex-col items-center font-medium">
                {/* non logged user menu */}
              </div>
            </>
          )}
        </div>

        {isAuthenticated ? (
          <div className="fixed bottom-0 left-0 flex w-full items-center justify-between bg-gray-100 p-4 dark:bg-gray-800">
            <div className="flex items-center gap-3">
              {userInfo && userInfo?.avatar ? (
                <Image
                  src={userInfo?.avatar}
                  alt="user profile"
                  width={30}
                  height={30}
                  className="size-8 select-none overflow-hidden rounded-full object-cover md:size-10"
                  style={{ boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)' }}
                />
              ) : (
                <Image
                  src="/avatar.png"
                  alt="user profile"
                  width={30}
                  height={30}
                  className="size-8 select-none rounded-full object-cover md:size-10"
                />
              )}
              <div>
                <p className="text-xs font-semibold dark:text-gray-200 md:text-sm">
                  {userInfo?.username}
                </p>
                <p className="text-[10px] dark:text-gray-300 md:text-xs">
                  {userInfo?.email}
                </p>
              </div>
            </div>
            <button
              onClick={() => {
                handleLogout();
              }}
              className="text-xs font-medium duration-200 ease-in-out active:scale-95 md:text-sm"
            >
              Logout
            </button>
          </div>
        ) : (
          <>
            <div className="fixed bottom-0 left-0 w-full bg-gray-100 p-4 dark:bg-gray-800">
              <Link
                href="/login"
                className="flex items-center justify-center gap-2 rounded-full bg-primary_color p-2 text-[10px] font-semibold text-white duration-250 ease-in-out hover:bg-primary_color/90 active:scale-95 md:p-3 md:text-xs"
              >
                <FaRegUserCircle className="text-base" />
                <span>Login / Register</span>
              </Link>
            </div>
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default ResponsiveDrawer;
