'use client';

import axios from 'axios';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { IoNotificationsOutline, IoNotificationsSharp } from '@/Icons/io5';
import { getCookie } from '@/utils/getCookie';
import { Popover, PopoverContent, PopoverTrigger } from '../../ui/popover';
import dateTimeConverter from '@/utils/dateTimeConverter';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import useSecureFetcher from '@/hooks/useSecureFetcher';
import {
  addNotificationsData,
  notificationsDataFetchStart,
} from '@/store/slices/notificationsDataSlice';
import { cn } from '@/utils/utils';

type TNotificationsProps = {
  notifications: any;
  isLoading: boolean;
  isSuccess: boolean;
};

export default function Notification() {
  const {
    notifications: notificationsData,
    isLoading,
    isSuccess,
  }: TNotificationsProps = useAppSelector((state) => state.notificationsData);

  const [open, setOpen] = useState(false);
  const [alignValue, setAlignValue] = useState<'start' | 'center' | 'end'>(
    'end',
  );

  const apiLink = process.env.NEXT_PUBLIC_LOCALHOST;
  const dispatch = useAppDispatch();

  // Update the `align` value based on window size
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        // Large screens (≥1024px)
        setAlignValue('start');
      } else {
        // Smaller screens (<1024px)
        setAlignValue('end');
      }
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchNotificationData = async () => {
    dispatch(notificationsDataFetchStart());
    const response = await useSecureFetcher('v1/message/all', {});
    if (response.status !== 'error') {
      dispatch(addNotificationsData(response.reverse()));
    }
  };

  const handleAllSeen = async () => {
    const jwt = getCookie('jwt');
    try {
      const response = await axios.post(
        `${apiLink}/message/allSeen`,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
          withCredentials: true,
        },
      );

      if (response.status === 200) {
        toast.success('All marked as read', {
          duration: 3000,
        });
      }
      fetchNotificationData();
    } catch (error) {}
  };

  const unseenNotificationData = notificationsData?.filter(
    (notification: { seen: boolean }) => notification.seen === false,
  );

  return (
    <Popover
      open={open}
      onOpenChange={(value) => {
        if (value && !isSuccess) {
          fetchNotificationData();
        }
        setOpen(value);
      }}
    >
      <PopoverTrigger
        className={`relative flex size-8 select-none items-center justify-center rounded-lg text-xl outline-none duration-250 ease-in-out 2xl:size-10 ${
          open ? 'bg-primary_color/10' : 'bg-gray-100 hover:bg-gray-200'
        }`}
      >
        {open ? (
          <IoNotificationsSharp className="text-primary_color" />
        ) : (
          <IoNotificationsOutline />
        )}

        {unseenNotificationData?.length > 0 && (
          <p
            className={`absolute flex select-none items-center justify-center overflow-hidden rounded-full bg-tertiary_color text-[10px] font-bold text-white ${
              unseenNotificationData?.length > 99
                ? '-right-2 -top-2 size-6'
                : unseenNotificationData?.length > 9
                ? '-right-[6px] -top-[6px] size-5'
                : '-right-1 -top-1 size-4'
            }`}
          >
            {unseenNotificationData?.length}
          </p>
        )}
      </PopoverTrigger>

      <PopoverContent
        align={alignValue}
        className={cn('h-fit w-72 p-0', alignValue === 'start' && 'mr-2')}
      >
        {/* top part */}
        <div className="sticky top-0 z-10 flex h-10 w-full select-none items-center justify-between rounded-t border-b-[0.5px] bg-white px-4 outline-none">
          <p className="flex items-center gap-1 text-sm font-bold">
            Notification
          </p>
          <button
            onClick={() => handleAllSeen()}
            className="text-xs font-medium duration-250 ease-in-out hover:text-primary_color"
          >
            Read all
          </button>
        </div>

        {/* content */}
        <div className="responsive-scrollbar relative flex max-h-72 w-full flex-col gap-1 overflow-y-auto p-2 text-sm">
          {isLoading ? (
            <>
              <h3 className="flex h-20 items-center justify-center text-xs font-medium text-gray-600">
                Oops! Notifications data loading at the moment.
              </h3>
            </>
          ) : notificationsData?.length > 0 ? (
            notificationsData?.map((notification: any) => (
              <Link
                key={notification?._id}
                href={`/notifications/${notification?._id}`}
                onClick={() => setOpen(false)}
                className={`relative flex items-start gap-2 rounded-lg px-3 py-2 hover:bg-primary_color/5 ${
                  !notification?.seen ? 'bg-primary_color/5' : 'bg-gray-100'
                }`}
              >
                <div className="w-full space-y-2">
                  <h1 className="text-sm font-semibold">
                    {notification?.title}
                  </h1>
                  <p className="text-xs font-medium text-gray-500">
                    {notification?.message}
                  </p>
                  <div className="flex items-center justify-between gap-3 pt-2">
                    <p className="flex items-center gap-1 text-xs font-medium">
                      {dateTimeConverter(notification?.createdAt).hour}:
                      {dateTimeConverter(notification?.createdAt).minute}{' '}
                      {dateTimeConverter(notification?.createdAt).ampm}
                    </p>
                    <p className="flex items-center gap-1 text-xs font-medium">
                      {dateTimeConverter(notification?.createdAt).day}{' '}
                      {dateTimeConverter(notification?.createdAt).monthName}{' '}
                      {dateTimeConverter(notification?.createdAt).year}
                    </p>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <>
              <h3 className="flex h-20 items-center justify-center text-xs font-medium text-gray-600">
                Oops! No notifications at the moment.
              </h3>
            </>
          )}
        </div>

        {/* bottom part */}
        <div className="sticky bottom-0 z-10 flex h-10 w-full items-center justify-center rounded-b bg-white px-2 text-xs font-medium">
          <Link
            href="/notifications"
            onClick={() => setOpen(false)}
            className="duration-250 ease-in-out hover:text-primary_color"
          >
            View all
          </Link>
        </div>
      </PopoverContent>
    </Popover>
  );
}
