'use client';

import { ReactNode, useEffect, useState } from 'react';
import { CustomLoading } from '@/components/custom/Loading/CustomLoading';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { addUserData } from '@/store/slices/userDataSlice';
import useSecureFetcher from '@/hooks/useSecureFetcher';
import { getCookie } from '@/utils/getCookie';
import usePublicFetcher from '@/hooks/usePublicFetcher';
import {
  addAirportsData,
  airportsDataFetchStart,
} from '@/store/slices/airportsDataSlice';
import {
  addAirlinesData,
  airlinesDataFetchStart,
} from '@/store/slices/airlinesDataSlice';

export const SessionProvider = ({ children }: { children: ReactNode }) => {
  const isUserDataSuccess = useAppSelector((state) => state.userData.isSuccess);
  const isAirportsDataSuccess = useAppSelector(
    (state) => state.airportsData.isSuccess,
  );
  const isAirlinesDataSuccess = useAppSelector(
    (state) => state.airlinesData.isSuccess,
  );

  const [isLoading, setIsLoading] = useState(
    isUserDataSuccess && isAirportsDataSuccess && isAirlinesDataSuccess
      ? false
      : true,
  );

  const token = getCookie('jwt');
  const refreshToken = getCookie('jwt');
  const dispatch = useAppDispatch();

  // Fetch User Data
  const fetchUserData = async () => {
    const response = await useSecureFetcher('v1/GetUserProfile', {});

    const data = {
      ...response.user,
      tokens: {
        token,
        refreshToken,
      },
    };

    if (response.status !== 'error') {
      dispatch(addUserData(data));
    }
    setIsLoading(false);
  };

  // Fetch Airports Data
  const fetchAirportsData = async () => {
    dispatch(airportsDataFetchStart());
    const response = await usePublicFetcher('v1/airport/all', {});
    if (response.status !== 'error') {
      dispatch(addAirportsData(response));
    }

    setIsLoading(false);
  };

  // Fetch Airlines Data
  const fetchAirlinesData = async () => {
    dispatch(airlinesDataFetchStart());
    const response = await usePublicFetcher('v1/aircontrol/airAllNoUser', {});
    if (response.status !== 'error') {
      dispatch(addAirlinesData(response));
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (isUserDataSuccess === false) {
      fetchUserData();
    }
    if (isAirportsDataSuccess === false) {
      fetchAirportsData();
    }
    if (isAirlinesDataSuccess === false) {
      fetchAirlinesData();
    }
  }, [dispatch]);

  if (isLoading) {
    return <CustomLoading />;
  }

  return children;
};
