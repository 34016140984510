'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { removeUserData } from '@/store/slices/userDataSlice';
import { BsFillGearFill } from '@/Icons/bs';
import { FaHandHoldingUsd } from '@/Icons/fa';
import { IoLogOut } from '@/Icons/io5';
import { Popover, PopoverContent, PopoverTrigger } from '../../ui/popover';

export default function UserProfile({ pathname }) {
  const user = useAppSelector((state) => state.userData.user);

  const [open, setOpen] = useState(false);
  const router = useRouter();
  const dispatch = useAppDispatch();

  const handleLogout = async () => {
    try {
      dispatch(removeUserData());
      localStorage.removeItem('userInfo');
      document.cookie =
        'userInfo=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie =
        'userEmail=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie =
        'userCID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie =
        'userRole=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie =
        'userPhone=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      router.replace('/');
    } catch (error) {}
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        className={`relative flex size-8 select-none items-center justify-center overflow-hidden rounded-lg text-xl outline-none duration-250 ease-in-out 2xl:size-10 ${
          open ? 'bg-primary_color/10' : 'bg-gray-100 hover:bg-gray-200'
        }`}
      >
        {user && user?.avatar ? (
          <Image
            src={`${user?.avatar}`}
            alt="user profile"
            width={30}
            height={30}
            className="size-full rounded-md object-cover"
          />
        ) : (
          <Image
            src="/avatar.png"
            alt="user profile"
            width={30}
            height={30}
            className="size-full rounded-md object-cover"
          />
        )}
      </PopoverTrigger>

      <PopoverContent align="end" className="w-56 p-0">
        <div className="flex items-center gap-3 border-b p-3 dark:border-gray-800">
          {user && user?.avatar ? (
            <Image
              src={`${user?.avatar}`}
              alt="user profile"
              width={30}
              height={30}
              className="size-10 min-h-10 min-w-10 select-none overflow-hidden rounded-lg object-cover"
            />
          ) : (
            <Image
              src="/avatar.png"
              alt="user profile"
              width={30}
              height={30}
              className="size-10 min-h-10 min-w-10 select-none overflow-hidden rounded-lg object-cover"
            />
          )}
          <div className="min-w-0">
            <p
              title={user?.username}
              className="truncate text-sm font-semibold dark:text-gray-200"
            >
              {user?.username}
            </p>
            {/* <p
              title={user?.email}
              className="truncate text-xs dark:text-gray-300"
            >
              {user?.email}
            </p> */}
            <p
              title={user?.CID}
              className="truncate text-xs dark:text-gray-300"
            >
              CID: {user?.CID}
            </p>
          </div>
        </div>

        <div className="p-2">
          <Link
            href="/deposits"
            onClick={() => {
              setOpen(!open);
            }}
            className={`flex w-full items-center justify-between gap-2 rounded-lg p-2 text-sm font-medium ${
              pathname === '/deposits'
                ? 'bg-primary_color/10 text-primary_color'
                : 'text-gray-500 hover:bg-primary_color/10 hover:text-primary_color'
            }`}
          >
            Deposit <FaHandHoldingUsd />
          </Link>

          <Link
            href="/my-profile"
            onClick={() => setOpen(false)}
            className={`flex w-full items-center justify-between gap-2 rounded-lg p-2 text-sm font-medium ${
              pathname === '/my-profile'
                ? 'bg-primary_color/10 text-primary_color'
                : 'text-gray-500 hover:bg-primary_color/10 hover:text-primary_color'
            }`}
          >
            My Profile
            <BsFillGearFill />
          </Link>
          <button
            onClick={() => {
              handleLogout();
            }}
            className="flex w-full items-center justify-between gap-2 rounded-lg p-2 text-sm font-medium text-gray-500 hover:bg-primary_color/10 hover:text-primary_color"
          >
            Logout
            <IoLogOut />
          </button>
        </div>
      </PopoverContent>
    </Popover>
  );
}
