// utils/urlHelpers.ts
import { useMemo } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';

export function normalizeUrl(url: string): string {
  try {
    if (!url) return '/';

    // Remove multiple consecutive slashes (except after protocol)
    let cleanUrl = url.replace(/([^:]\/)\/+/g, '$1');

    // Remove trailing slashes
    cleanUrl = cleanUrl.replace(/\/+$/, '');

    // Split URL into base and query
    const [base, query] = cleanUrl.split('?');

    // If no query parameters, return cleaned base
    if (!query) return cleanUrl;

    // Parse and normalize query parameters
    const params = new URLSearchParams(query);
    const normalizedParams = new URLSearchParams();

    for (const [key, value] of (params as any).entries()) {
      if (key && value) {
        // Decode first to handle any already encoded values
        const decodedValue = decodeURIComponent(value);
        // Encode while preserving allowed special characters
        const normalizedValue = encodeURIComponent(decodedValue)
          .replace(/%2F/g, '/') // Preserve forward slashes
          .replace(/%3D/g, '=') // Preserve equals signs
          .replace(/%26/g, '&') // Preserve ampersands
          .replace(/%3F/g, '?'); // Preserve question marks

        normalizedParams.append(key, normalizedValue);
      }
    }

    const queryString = normalizedParams.toString();
    return queryString ? `${base}?${queryString}` : base;
  } catch (error) {
    console.error('URL normalization error:', error);
    return '/';
  }
}

// Header component helper
export function createRedirectUrl(
  pathname: string,
  searchParams: URLSearchParams,
): string {
  try {
    // Clean the pathname
    const cleanPath = pathname.replace(/^\/+|\/+$/g, '');

    // Convert searchParams to string
    const queryString = searchParams.toString();

    // Combine and normalize
    const fullUrl = queryString ? `${cleanPath}?${queryString}` : cleanPath;

    return encodeURIComponent(fullUrl);
  } catch (error) {
    console.error('Error creating redirect URL:', error);
    return encodeURIComponent('/');
  }
}

// Login page helper
export function getRedirectUrl(url: string | null): string {
  try {
    if (!url) return '/';
    return decodeURIComponent(url);
  } catch (error) {
    console.error('Error parsing redirect URL:', error);
    return '/';
  }
}

// Custom hook for redirect URL
export function useRedirectUrl() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  return useMemo(
    () => createRedirectUrl(pathname, searchParams),
    [pathname, searchParams],
  );
}
