import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type timeGroup = {
  value: any;
};

const initialState: timeGroup = {
  value: '',
};

export const timeGroupSlice = createSlice({
  name: 'timeGroup',
  initialState,
  reducers: {
    setTimeGroup: (state, action: PayloadAction<{ value: any }>) => {
      const { value } = action.payload;
      state.value = value;
    },
  },
});

export const { setTimeGroup } = timeGroupSlice.actions;
export default timeGroupSlice.reducer;
