import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type LoginState = {
  identifier: string;
  password: string;
};

const initialState: LoginState = {
  identifier: 'nadim-chowdhury@outlook.com',
  password: 'lieo1234',
};

export const userLoginSlice = createSlice({
  name: 'userLogin',
  initialState,
  reducers: {
    userLogin: (
      state,
      action: PayloadAction<{ identifier: string; password: string }>,
    ) => {
      const { identifier, password } = action.payload;
      state.identifier = identifier;
      state.password = password;
    },
  },
});

export const { userLogin } = userLoginSlice.actions;
export default userLoginSlice.reducer;
