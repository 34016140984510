import { createSlice } from '@reduxjs/toolkit';

const holdConfirmSlice = createSlice({
  name: 'allPassengers',
  initialState: {
    data: {},
  },
  reducers: {
    setHoldConfirmItem: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setHoldConfirmItem } = holdConfirmSlice.actions;
export default holdConfirmSlice.reducer;
