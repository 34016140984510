import { createSlice } from '@reduxjs/toolkit';

const searchResultsSlice = createSlice({
  name: 'searchResults',
  initialState: {
    data: {
      result: [],
    },
    // data: returnSearchResponse.data,
    loading: false,
    error: null,
  },
  reducers: {
    fetchDataStart: (state) => {
      state.loading = true;
    },
    fetchDataPending: (state, action) => {
      state.loading = true;
      state.data = action.payload;
    },
    fetchDataSuccess: (state) => {
      state.loading = false;
    },
    fetchDataFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchDataStart,
  fetchDataPending,
  fetchDataSuccess,
  fetchDataFailure,
} = searchResultsSlice.actions;
export default searchResultsSlice.reducer;
