import { createSlice } from '@reduxjs/toolkit';

const airControlSlice = createSlice({
  name: 'airControlSlice',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {
    fetchAirControlDataStart: (state) => {
      state.loading = true;
    },
    fetchAirControlDataSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchAirControlDataFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchAirControlDataStart,
  fetchAirControlDataSuccess,
  fetchAirControlDataFailure,
} = airControlSlice.actions;
export default airControlSlice.reducer;
