import { createSlice } from '@reduxjs/toolkit';

const clientPriceSlice = createSlice({
  name: 'user',
  initialState: {
    price: '',
  },
  reducers: {
    setPrice: (state, action) => {
      state.price = action.payload;
    },
  },
});

export const { setPrice } = clientPriceSlice.actions;
export default clientPriceSlice.reducer;
