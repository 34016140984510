export const getCookie = (name: string): string | null => {
  if (typeof document === 'undefined') {
    return null;
  }

  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? decodeURIComponent(match[2]) : null;
};

export const setCookie = (name: string, value: string, days: number = 7) => {
  if (typeof document === 'undefined') {
    return;
  }

  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie =
    name + '=' + encodeURIComponent(value || '') + expires + '; path=/';
};

export const deleteCookie = (name: string) => {
  if (typeof document === 'undefined') {
    return;
  }

  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
};
