import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type LoginState = {
  role: string;
};

const initialState: LoginState = {
  role: '',
};

export const userRoleSlice = createSlice({
  name: 'userRole',
  initialState,
  reducers: {
    setUserRole: (state, action: PayloadAction<{ role: string }>) => {
      const { role } = action.payload;
      state.role = role;
    },
  },
});

export const { setUserRole } = userRoleSlice.actions;
export default userRoleSlice.reducer;
