export const manage = [
  { value: 'deposits', label: 'Deposits' },
  { value: 'group-fare', label: 'Group Fare' },
  { value: 'airlines', label: 'Airlines' },
  { value: 'airports', label: 'Airports' },
  { value: 'users', label: 'Users' },
  { value: 'others', label: 'Others' },
  // { value: "accounts", label: "Accounts" },
];

export const controls = [
  { value: 'transactions', label: 'Transactions' },
  { value: 'workboard', label: 'Workboard' },
  { value: 'ledger', label: 'Ledger' },
  { value: 'passengers', label: 'Passengers' },
];

export const adminMenu = [
  { value: 'deposits', label: 'Deposits' },
  { value: 'airlines', label: 'Airlines' },
  { value: 'airports', label: 'Airports' },
];

export const B2BMenu = [
  { value: 'deposits', label: 'Deposits' },
  { value: 'passengers', label: 'Passengers' },
  { value: 'group-fare', label: 'Group Fare' },
  { value: 'ledger', label: 'Ledger' },
  { value: 'reference', label: 'Reference' },
  { value: 'upcoming-travel-date', label: 'Upcoming Date' },
];

export const B2CMenu = [
  { value: 'deposits', label: 'Deposits' },
  { value: 'ledger', label: 'Ledger' },
  { value: 'passengers', label: 'Passengers' },
  { value: 'upcoming-travel-date', label: 'Upcoming Date' },
  // { value: "accounts", label: "Accounts" },
];

// export const others = [
//   { value: 'about-us', label: 'About Us' },
//   { value: 'why-ticketmet', label: 'Why Ticketmet?' },
//   { value: 'travel-guide', label: 'Travel Guide' },
//   { value: 'FAQ-&-support', label: 'FAQ & Support' },
//   { value: 'privacy-policy', label: 'Privacy Policy' },
//   { value: 'terms-&-conditions', label: 'Terms & Conditions' },
// ];
