import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { IoIosArrowDown } from '@/Icons/io';
import { usePathname } from 'next/navigation';

export const DrawerItem = ({ menu, title, setIsOpen }) => {
  const [open, setOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const pathname = usePathname();

  // Check if any menu item matches the current pathname
  useEffect(() => {
    const active = menu.some((item: any) => pathname === `/${item.value}`);
    setIsActive(active);
    active && setOpen(true);
  }, [pathname, menu]);

  return (
    <div className="w-full">
      <button
        onClick={() => {
          return setOpen(!open);
        }}
        className={`flex w-full items-center justify-between px-5 py-2 text-sm outline-none duration-250 ease-in-out active:scale-95 ${
          open || isActive
            ? 'bg-primary_color/10 text-primary_color'
            : 'hover:bg-primary_color/10'
        }`}
      >
        {title}
        <span
          className={`text-lg duration-250 ${
            open ? 'rotate-180 text-primary_color' : 'rotate-0 text-gray-500'
          }`}
        >
          <IoIosArrowDown />
        </span>
      </button>

      {open && (
        <div className="z-50 flex w-full flex-col text-gray-600 dark:text-gray-400">
          {menu.map((item: any) => (
            <Link
              key={item.value}
              onClick={() => setIsOpen(false)}
              href={`/${item.value}`}
              className={`px-10 py-2 text-sm font-medium duration-250 ease-in-out active:scale-95 ${
                pathname === '/' + item.value
                  ? 'bg-primary_color/10 text-primary_color'
                  : 'hover:bg-primary_color/10 hover:text-primary_color'
              }`}
            >
              {item.label}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};
