import { useEffect, useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '../../ui/popover';
import { MdOutlineSupportAgent } from '@Icons/md';
import { cn } from '@/utils/utils';

const HelpCentre = () => {
  const [open, setOpen] = useState(false);
  const [alignValue, setAlignValue] = useState<'start' | 'center' | 'end'>(
    'end',
  );
  // Update the `align` value based on window size
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        // Large screens (≥1024px)
        setAlignValue('start');
      } else {
        // Smaller screens (<1024px)
        setAlignValue('end');
      }
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        className={`relative flex size-8 select-none items-center justify-center rounded-lg text-xl outline-none duration-250 ease-in-out 2xl:size-10 ${
          open ? 'bg-primary_color/10' : 'bg-gray-100 hover:bg-gray-200'
        }`}
      >
        <MdOutlineSupportAgent
          className={open ? 'text-primary_color' : 'text-gray-500'}
        />
      </PopoverTrigger>

      <PopoverContent
        align={alignValue}
        className={cn(
          'h-fit w-60 space-y-3 p-3',
          alignValue === 'start' && 'mr-2',
        )}
      >
        <h3 className="text-center font-semibold">MAS Yasin Arafat</h3>
        <div className="flex items-center gap-2 text-xs font-medium">
          <p className="flex w-12 items-center justify-between">
            <span>Email</span> <span>:</span>
          </p>
          arafat0951@gmail.com
        </div>
        <div className="flex items-center gap-2 text-xs font-medium">
          <p className="flex w-12 items-center justify-between">
            <span>Phone</span> <span>:</span>
          </p>
          +8801727706200
        </div>
        <div className="w-full border-b bg-gray-200"></div>
        <div className="flex items-center gap-2 text-xs font-medium">
          <p className="flex w-12 items-center justify-between">
            <span>Branch</span> <span>:</span>
          </p>
          Mirpur
        </div>
        <div className="flex items-center gap-2 text-xs font-medium">
          <p className="flex w-12 items-center justify-between">
            <span>Email</span> <span>:</span>
          </p>
          support@ticketmet.com
        </div>
        <div className="flex items-center gap-2 text-xs font-medium">
          <p className="flex w-12 items-center justify-between">
            <span>Phone</span> <span>:</span>
          </p>
          +8801710007666
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default HelpCentre;
