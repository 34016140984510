interface DateTimeObject {
  day: number;
  month: number;
  monthName: string;
  year: number;
  hour: number;
  minute: string;
  ampm: string;
  weekDay: string;
}

function dateTimeConverter(
  dateTimeString: string | number | Date,
): DateTimeObject {
  // Normalize input to handle single-digit minutes like "2024-12-13T23:0"
  if (
    typeof dateTimeString === 'string' &&
    /\d{4}-\d{2}-\d{2}T\d{2}:\d{1}$/.test(dateTimeString)
  ) {
    // Append a leading zero to single-digit minutes
    dateTimeString = dateTimeString.replace(/:(\d)$/, ':0$1');
  }

  // Ensure the input is interpreted as UTC by appending 'Z' if needed
  if (typeof dateTimeString === 'string' && !dateTimeString.endsWith('Z')) {
    dateTimeString += 'Z';
  }

  const date = new Date(dateTimeString);

  if (isNaN(date.getTime())) {
    throw new Error('Invalid date input');
  }

  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1; // Months are zero-based
  const year = date.getUTCFullYear();
  const hours24 = date.getUTCHours();

  // Ensure minutes are always two digits
  const minute = date.getUTCMinutes().toString().padStart(2, '0');

  // Convert 24-hour format to 12-hour format and determine AM/PM
  const hour = hours24 % 12 || 12;
  const ampm = hours24 >= 12 ? 'PM' : 'AM';

  // Get month name
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const monthName = monthNames[date.getUTCMonth()];

  // Get day of the week
  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const weekDay = weekDays[date.getUTCDay()];

  const dateTimeObject: DateTimeObject = {
    day,
    month,
    monthName,
    year,
    hour,
    minute,
    ampm,
    weekDay,
  };
  return dateTimeObject;
}

export default dateTimeConverter;
