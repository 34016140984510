'use client';

import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/navigation';
import { NavbarItem } from './NavbarItem';
import Notification from './Notification';
import ResponsiveDrawer from './ResponsiveDrawer';
import UserProfile from './UserProfile';
import { useAppSelector } from '@/store/hooks';
import { useAppDispatch } from '@/store/hooks';
import { flightSearchClose } from '@/store/slices/flightSearchSlice';
import { FaRegUserCircle } from '@/Icons/fa';
import React from 'react';
import HelpCentre from './HelpCentre';
import MyBalance from './MyBalance';
import { B2BMenu, B2CMenu, controls, manage } from '@/constants/menuItems';
import { useRedirectUrl } from '@/utils/urlHelpers';
const Header = () => {
  const userData = useAppSelector((state) => state.userData);

  const router = useRouter();
  const dispatch = useAppDispatch();
  const pathname = usePathname();
  const redirectUrl = useRedirectUrl();

  if (pathname.startsWith('/print-preview')) return null;

  return (
    <header className="fixed top-0 z-50 w-full border-b border-gray-100 bg-white dark:border-gray-800 dark:bg-gray-900">
      <nav className="custom-container relative flex items-center justify-between gap-3 py-[14px]">
        {/* ===> nav left side <=== */}
        <div className="flex min-w-0 items-center gap-3">
          <div className="min-w-6 overflow-hidden 2xl:hidden">
            <ResponsiveDrawer
              userInfo={userData?.user}
              isAuthenticated={userData?.isLogged}
            />
          </div>
          <div
            onClick={() => {
              dispatch(flightSearchClose());
              router.replace('/');
            }}
            className="flex min-w-0 cursor-pointer items-center gap-2"
          >
            {userData?.user?.role === 'B2C' ||
            userData?.user?.role === 'VIP' ? (
              <>
                <Image
                  src="/tmlogo.png"
                  alt="TicketMet Logo"
                  width={36}
                  height={36}
                  className="size-7 rounded-full object-cover 2xl:size-8"
                />

                <h1 className="text-xl font-bold tracking-tighter 2xl:text-2xl">
                  Ticketmet
                </h1>
              </>
            ) : userData?.user?.role === 'ADMIN' ||
              userData?.user?.role === 'SUDO' ||
              userData?.user?.role === 'MOD' ||
              userData?.user?.role === 'B2B' ? (
              <>
                <Image
                  src={userData?.user?.company?.logo}
                  alt="Logo"
                  width={36}
                  height={36}
                  className="size-7 rounded-full object-cover 2xl:size-8"
                />

                <h1 className="truncate font-bold tracking-tighter 2xl:text-xl">
                  {userData?.user?.company?.name}
                </h1>
              </>
            ) : (
              <>
                <Image
                  src="/tmlogo.png"
                  alt="TicketMet Logo"
                  width={36}
                  height={36}
                  className="size-7 rounded-full object-cover 2xl:size-8"
                />
                <h1 className="text-xl font-bold tracking-tighter 2xl:text-2xl">
                  Ticketmet
                </h1>
              </>
            )}
          </div>
        </div>

        {/* ===> nav right side <=== */}
        <div className="relative flex items-center justify-end gap-2 2xl:gap-10">
          {userData.isLogged ? (
            <div className="hidden items-center gap-5 text-sm font-medium dark:text-gray-200 2xl:flex">
              {userData?.user?.role === 'SUDO' ||
              userData?.user?.role === 'ADMIN' ? (
                <>
                  <Link
                    href="/my-bookings"
                    className={`duration-250 ease-in-out dark:hover:text-gray-300 ${
                      pathname === '/my-bookings'
                        ? 'text-primary_color'
                        : 'hover:text-primary_color'
                    }`}
                  >
                    My Bookings
                  </Link>
                  <Link
                    href="/reports"
                    className={`duration-250 ease-in-out dark:hover:text-gray-300 ${
                      pathname === '/reports'
                        ? 'text-primary_color'
                        : 'hover:text-primary_color'
                    }`}
                  >
                    Reports
                  </Link>
                  <NavbarItem title="Controls" menu={controls} />
                  <NavbarItem title="Manage" menu={manage} />
                </>
              ) : (
                <></>
              )}

              {userData?.user?.role === 'B2C' ||
              userData?.user?.role === 'VIP' ? (
                <>
                  <Link
                    href="/my-bookings"
                    className={`duration-250 ease-in-out dark:hover:text-gray-300 ${
                      pathname === '/my-bookings'
                        ? 'text-primary_color'
                        : 'hover:text-primary_color'
                    }`}
                  >
                    My Bookings
                  </Link>
                  <NavbarItem title="Accounts" menu={B2CMenu} />
                </>
              ) : (
                <></>
              )}

              {userData?.user?.role === 'B2B' ? (
                <>
                  <Link
                    href="/my-bookings"
                    className={`duration-250 ease-in-out dark:hover:text-gray-300 ${
                      pathname === '/my-bookings'
                        ? 'text-primary_color'
                        : 'hover:text-primary_color'
                    }`}
                  >
                    My Bookings
                  </Link>
                  <NavbarItem title="Accounts" menu={B2BMenu} />
                </>
              ) : (
                <></>
              )}

              {userData?.user?.role === 'MOD' ? (
                <>
                  <Link
                    href="/workboard"
                    className={`duration-250 ease-in-out dark:hover:text-gray-300 ${
                      pathname === '/workboard'
                        ? 'text-primary_color'
                        : 'hover:text-primary_color'
                    }`}
                  >
                    Workboard
                  </Link>
                </>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}

          <div className="flex items-center gap-3">
            {userData.isLogged ? (
              <>
                <MyBalance />
                <Notification />
                <HelpCentre />
                <UserProfile pathname={pathname} />
              </>
            ) : (
              <Link
                href={`/login?redirect=${redirectUrl}`}
                className="flex items-center gap-2 rounded-lg bg-primary_color px-3 py-2 text-xs font-medium text-white duration-250 ease-in-out hover:bg-primary_color/90 active:scale-95 2xl:p-3"
              >
                <FaRegUserCircle className="text-base" />
                <span>
                  Login{' '}
                  <span className="hidden 2xl:inline-block"> / Register</span>
                </span>
              </Link>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
