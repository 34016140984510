import './customLoading.css';

export const CustomLoading = () => {
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="size-[112px] scale-50">
        <div className="box1"></div>
        <div className="box2"></div>
        <div className="box3"></div>
      </div>
    </div>
  );
};
