import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SignupState = {
  username: string;
  email: string;
  phone: string;
  password: string;
  ref: string;
};

const initialState: SignupState = {
  username: '',
  email: '',
  phone: '',
  password: '',
  ref: '',
};

export const userSignupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    userSignup: (state, action: PayloadAction<SignupState>) => {
      const { username, email, phone, password, ref } = action.payload;
      state.username = username;
      state.email = email;
      state.phone = phone;
      state.password = password;
      state.ref = ref;
    },
  },
});

export const { userSignup } = userSignupSlice.actions;
export default userSignupSlice.reducer;
