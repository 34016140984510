'use client';

import { NextUIProvider } from '@nextui-org/react';
import { ThemeProvider } from 'next-themes';
import { Provider } from 'react-redux';
import { Toaster } from '@/components/ui/sonner';
import { store } from '@/store/store';
import { SessionProvider } from '@/lib/services/SessionProvider';
import NextTopLoader from 'nextjs-toploader';
import CustomTopLoader from '@/components/custom/CustomTopLoader';
import Header from '@/components/layout/header/Header';

const RootLayout = ({ children }) => {
  return (
    <>
      <Provider store={store}>
        <NextUIProvider>
          <ThemeProvider
            attribute="class"
            defaultTheme="light"
            disableTransitionOnChange
          >
            <SessionProvider>
              <NextTopLoader
                color="#3333FF"
                showSpinner={false}
                shadow="false"
              />
              <CustomTopLoader />
              <Header />
              {children}
              <Toaster expand={true} richColors closeButton />
            </SessionProvider>
          </ThemeProvider>
        </NextUIProvider>
      </Provider>
    </>
  );
};

export default RootLayout;
