import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type typeGroupValue = {
  value: any;
};

const initialState: typeGroupValue = {
  value: '',
};

export const typeGroupSlice = createSlice({
  name: 'typeGroupValue',
  initialState,
  reducers: {
    setTypeGroup: (state, action: PayloadAction<{ value: any }>) => {
      const { value } = action.payload;
      state.value = value;
    },
  },
});

export const { setTypeGroup } = typeGroupSlice.actions;
export default typeGroupSlice.reducer;
