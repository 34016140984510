import { createSlice } from '@reduxjs/toolkit';

const confirmBookSlice = createSlice({
  name: 'user',
  initialState: {
    data: {},
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setData } = confirmBookSlice.actions;
export default confirmBookSlice.reducer;
