import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type searchType = {
  type: any;
};

const initialState: searchType = {
  type: '',
};

export const searchTypeSlice = createSlice({
  name: 'searchType',
  initialState,
  reducers: {
    SetSearchType: (state, action: PayloadAction<{ type: any }>) => {
      const { type } = action.payload;
      state.type = type;
    },
  },
});

export const { SetSearchType } = searchTypeSlice.actions;
export default searchTypeSlice.reducer;
