'use client';

import { useEffect } from 'react';
import { usePathname } from 'next/navigation';
import Head from 'next/head';
import * as gtag from '@/lib/gtag';

const HeadSection = () => {
  const pathname = usePathname(); // Get the current path

  useEffect(() => {
    if (pathname) {
      gtag.pageView(pathname); // Trigger page view on route change
    }
  }, [pathname]);

  return (
    <Head>
      {/* Google Analytics Script */}
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
    </Head>
  );
};

export default HeadSection;
