import { useAppSelector } from '@/store/hooks';
import { useEffect, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';

const CustomTopLoader = () => {
  const flightSearchData = useAppSelector((state) => state.flightSearch);

  const [percent, setPercent] = useState(0);

  useEffect(() => {
    setPercent(flightSearchData.searchPercent || 0);
  }, [flightSearchData]);

  return (
    <>
      <LoadingBar color="#3333FF" height={3} progress={percent} />
    </>
  );
};

export default CustomTopLoader;
