import { createSlice } from '@reduxjs/toolkit';

const allPassengersDataSlice = createSlice({
  name: 'allPassengers',
  initialState: {
    data: [],
  },
  reducers: {
    setAllPassengers: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setAllPassengers } = allPassengersDataSlice.actions;
export default allPassengersDataSlice.reducer;
