import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TNotification = {
  CID: string;
  email: string;
  type: string;
  ref: string;
  title: string;
  message: string;
  seen: boolean;
  opened: boolean;
  doneBy: string;
  createdAt: string;
  updatedAt: string;
};
type TNotificationsDataState = {
  notifications: TNotification;
  isLoading: boolean;
  isSuccess: boolean;
  error: boolean;
  errorMessage: string | null;
};

const initialState: TNotificationsDataState = {
  notifications: null,
  isLoading: false,
  isSuccess: false,
  error: false,
  errorMessage: null,
};
const notificationsDataSlice = createSlice({
  name: 'notificationsData',
  initialState,
  reducers: {
    notificationsDataFetchStart: (state) => {
      state.isLoading = true;
    },
    addNotificationsData: (state, action: PayloadAction<TNotification>) => {
      state.notifications = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.error = false;
      state.errorMessage = null;
    },
  },
});

export const { addNotificationsData, notificationsDataFetchStart } =
  notificationsDataSlice.actions;
export default notificationsDataSlice.reducer;
