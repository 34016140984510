import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { IoIosArrowDown } from '@/Icons/io';
import { Popover, PopoverContent, PopoverTrigger } from '../../ui/popover';
import { usePathname } from 'next/navigation';

export function NavbarItem({ title, menu }: any) {
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const pathname = usePathname();

  // Check if any menu item matches the current pathname
  useEffect(() => {
    const active = menu.some((item: any) => pathname === `/${item.value}`);
    setIsActive(active);
  }, [pathname, menu]);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger
        className={`flex items-center gap-1 text-sm duration-250 ease-in-out ${
          isActive
            ? 'text-primary_color'
            : isOpen
            ? 'text-primary_color'
            : 'hover:text-primary_color'
        }`}
      >
        {title}
        <span
          className={`mt-1 transition-all duration-250 ease-soft-spring ${
            isOpen ? 'rotate-180' : 'rotate-0'
          }`}
        >
          <IoIosArrowDown />
        </span>
      </PopoverTrigger>
      <PopoverContent className="flex w-40 flex-col p-3">
        {menu.map((item: any) => (
          <Link
            onClick={() => setIsOpen(!isOpen)}
            key={item.value}
            href={`/${item.value}`}
            className={`whitespace-nowrap rounded-md px-3 py-[6px] text-sm ${
              pathname === '/' + item.value
                ? 'bg-primary_color/10 text-primary_color'
                : 'text-gray-500 hover:bg-primary_color/10 hover:text-primary_color'
            } `}
          >
            {item.label}
          </Link>
        ))}
      </PopoverContent>
    </Popover>
  );
}
