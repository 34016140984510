'use client';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type balanceState = {
  balance: number;
};

const initialState: balanceState = {
  balance: 0,
};

export const balanceSlice = createSlice({
  name: 'userBalance',
  initialState,
  reducers: {
    userBalance: (state, action: PayloadAction<{ balance: number }>) => {
      const { balance } = action.payload;
      state.balance = balance;
    },
  },
});

export const { userBalance } = balanceSlice.actions;
export default balanceSlice.reducer;
